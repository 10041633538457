import { get, post } from "../helpers/api_helper"
import * as url from "./url_helper"
import {GET_ALL_INQUIRY, GET_INQUIRY_STATUS, POST_SEARCH_INQUIRY} from "./url_helper";



export const InquiryRegister = (data) => post(url.POST_INQUIRY, data)
export const InquirySearch = (data) => post(url.POST_SEARCH_INQUIRY, data)

export const getAllInquiry = () => get(url.GET_ALL_INQUIRY)
export const getInquiryStatus = () => get(url.GET_INQUIRY_STATUS)
