import { createSlice } from '@reduxjs/toolkit';



const airwallexSlice = createSlice({
  name: 'airwallex',
  initialState: { token: null, loading : false },
  reducers: {

    setToken: (state, action) => {
        state.token = action.payload;
      },

  },
  
});

export const { setToken } = airwallexSlice.actions;
export default airwallexSlice.reducer;
