//SIGN_UP
export const POST_SIGNIN = "/tps-auth-service/api/auth/authenticate"
export const POST_SIGNUP_REGISTER = "/user-management-service/api/user/register"


export const TOKEN_VERIFY = "/tps-auth-service/api/auth/validate-token"
// export const POST_SIGNUP_PROFILE = "/user-management-service/api/user/register?step=2" 
// export const POST_SIGNUP_SECURITY_QUESTION = "/user-management-service/api/user/register?step=3"
// export const SIGN_UP_BUSINESS_DATA = "/user-management-service/api/user/register?step=4"
// export const SIGN_UP_DIRECTORS_DATA = "/user-management-service/api/user/register?step=5"

export const SIGN_UP_DATA_RETRIVE = "/user-management-service/api/user/get-user?email="

//forget password
export const SEND_OTP_TO_PHONE = "/tps-notification-service/api/otp/send"
export const OTP_VERIFICATION = "/tps-notification-service/api/otp/verify?"
export const ANSWER_SECURITY_QUESTIONS = "/user-management-service/api/user/security-questions-answers"
export const CHANGE_PASSWORD = "/user-management-service/api/user/change-password"
export const GET_SECURITY_QUESTION_BY_ID = "/user-management-service/api/user/security-questions-by-user-id?userId="



//Common
export const GET_COUNTRIES = "/tps-master-service/api/master/country"
export const GET_TITLES = "/tps-master-service/api/master/user"
export const GET_SECURITY_QUESTION = "/user-management-service/api/user/security-questions"
export const GET_BANK_CODE = "/tps-master-service/api/master/bankCode"
export const GET_BANK_CURRENCY = "/tps-master-service/api/master/bankCurrency"
export const GET_CITY = "/tps-master-service/api/master/city"
export const GET_ID_TYPE = "/tps-master-service/api/master/id_type"




//profile
export const UPDATE_USER_DETAILS = "/user-management-service/api/user/update-user"
export const UPDATE_PASSWORD_WITH_CURRENT_ONE = "/user-management-service/api/user/update-password"
export const SAVE_SECURITY_QUESTION_FIRST_TIME = "/user-management-service/api/user/save-sequrity-question-answers"
export const UPDATE_SECURITY_QUESTIONS_AND_ANSWERS = "/user-management-service/api/user/update-sequrity-question-answers" 
export const CHANGE_USER_LANGUGE = "/user-management-service/api/user/update-user"


//KYB Dashboard
export const GET_ALL_KYB_USERS = "/user-management-service/api/user/get-active-users"
export const GET_KYB_USER_DETAILS_BY_ID = "/user-management-service/api/user/get-kyb-details-by-user?airwallexId="
export const GET_All_KYB_USER_DETAILS = "/user-management-service/api/user/get-all-users"

//user management
export const GET_TPS_USERS = "/user-management-service/api/backoffice-user/get-all"
export const CREATE_TPS_USERS = "/user-management-service/api/backoffice-user/save"
export const UPDATE_TPS_USER = "/user-management-service/api/backoffice-user/update"

//Inquiry
export const GET_ALL_INQUIRY = "/user-management-service/api/inquiry/get-all"
export const GET_INQUIRY_STATUS = "/tps-master-service/api/master/inquiry-status"

export const POST_INQUIRY = "/user-management-service/api/inquiry/save-response"
export const POST_SEARCH_INQUIRY = "/user-management-service/api/inquiry/filter-inquiry"

//Contact Request
export const GET_ALL_CONTACT_REQUEST = "/user-management-service/api/contact-request"

//Global Account
export const GET_ALL_GLOBAL_ACCOUNT = "/tps-account-management-service/api/global-account/get-all-account-balance"

//transaction History
export const GET_ALL_TRANSACTION_HISTORY = "/tps-transaction-management-service/api/fund-transfer/get-all"
export const GET_CONFIRMATION_LETTER = "/tps-transaction-management-service/api/fund-transfer/get-confirmation-letter"

//Charge
export const GET_ALL_CHARGES = "/tps-transaction-management-service/api/charge/getAll"
export const FILTER_ALL_CHARGES = "/tps-transaction-management-service/api/charge/filter"

//Threshold Management
export const GET_THRESHOLD_MANAGEMENT = "/tps-transaction-management-service/api/config/get-by-config-type?configType="
export const SAVE_THRESHOLD_MANAGEMENT = "/tps-transaction-management-service/api/config/save"
export const UPDATE_THRESHOLD_MANAGEMENT = "/tps-transaction-management-service/api/config/update"