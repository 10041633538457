import PropTypes from 'prop-types';
import React, { useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, Form, FormFeedback, Label, Input, TabContent,
  TabPane,
  NavItem,
  NavLink, } from "reactstrap";
import AnswerSecurityQuestions from "../AuthenticationInner/answer-security-questions";
import OTPVerifications from "../AuthenticationInner/otp-verification";
import PasswordChange from '../AuthenticationInner/passwordChange';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";


// import { withRouter, Link } from "react-router-dom"
import { Link } from 'react-router-dom';


// import images
import logoSm from "../../assets/images/logo/Logo-Icon.webp";

const ForgetPasswordPage = props => {
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false)
  const [securityQuestionsVerified, setSecurityQuestionsVerified] = useState(false)
  const [coUserId, setCoUserId] = useState(null)
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      // dispatch(userForgetPassword(values, props.history));
    }
  });



  document.title = "Forget Password | TPS Solutions";
  return (
    <React.Fragment>
      {/*<div className="home-btn d-none d-sm-block">*/}
      {/*  <Link to="/" className="text-dark">*/}
      {/*    <i className="fas fa-home h2"></i>*/}
      {/*  </Link>*/}
      {/*</div>*/}
      <div className="account-pages h-100vh">
        <Container className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col md={8} lg={7} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">Forget Password</h5>
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="36" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">

                  {
                    phoneNumberVerified === false ? <OTPVerifications  setPhoneNumberVerified={setPhoneNumberVerified} setCoUserId={setCoUserId}  /> : null
                  }

                  {                   
                    (securityQuestionsVerified === false && phoneNumberVerified === true) ? <AnswerSecurityQuestions setSecurityQuestionsVerified={setSecurityQuestionsVerified} coUserId={coUserId}  /> : null
                  }

                  {
                    (securityQuestionsVerified === true && phoneNumberVerified === true) ? <PasswordChange coUserId={coUserId} /> : null
                  }

                  

                  {/* <OTPVerifications/> */}
                   
                  {/* <AnswerSecurityQuestions/> */}


                  <Row className="mt-4">
                    <Col md={12} className="text-center">
                      <p className="mb-1">Remember It ? <Link to="/login" className="fw-medium text-primary"> Login Here </Link> </p>
                      <p className="mb-0">
                        © {new Date().getFullYear()} TPS Solutions.
                      </p>
                    </Col>
                  </Row>

                  {/*{props.forgetError && props.forgetError ? (*/}
                  {/*  <Alert color="danger" style={{ marginTop: "13px" }} className="mt-5">*/}
                  {/*    {props.forgetError}*/}
                  {/*  </Alert>*/}
                  {/*) : null}*/}
                  {/*{props.forgetSuccessMsg ? (*/}
                  {/*  <Alert color="success" style={{ marginTop: "13px" }} className="mt-5">*/}
                  {/*    {props.forgetSuccessMsg}*/}
                  {/*  </Alert>*/}
                  {/*) : null}*/}

                  {/*<Form*/}
                  {/*  onSubmit={(e) => {*/}
                  {/*    e.preventDefault();*/}
                  {/*    validation.handleSubmit();*/}
                  {/*    return false;*/}
                  {/*  }}*/}
                  {/*  className="mt-4">*/}

                  {/*  <div className="mb-3">*/}
                  {/*    <Label className="form-label" htmlor="useremail">Email</Label>*/}
                  {/*    <Input*/}
                  {/*      name="email"*/}
                  {/*      className="form-control"*/}
                  {/*      placeholder="Enter email"*/}
                  {/*      type="email"*/}
                  {/*      onChange={validation.handleChange}*/}
                  {/*      onBlur={validation.handleBlur}*/}
                  {/*      value={validation.values.email || ""}*/}
                  {/*      invalid={*/}
                  {/*        validation.touched.email && validation.errors.email ? true : false*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*    {validation.touched.email && validation.errors.email ? (*/}
                  {/*      <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>*/}
                  {/*    ) : null}*/}
                  {/*  </div>*/}

                  {/*  <div className="row mb-5">*/}
                  {/*    <div className="col-12 text-center">*/}
                  {/*      <button className="btn btn-primary w-lg waves-effect waves-light" type="submit">Reset</button>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</Form>*/}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
};

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default ForgetPasswordPage;