import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle, Badge,
} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {toast} from "react-toastify";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import {useSelector} from "react-redux";
import UserModel from "./components/UserModel";
import { GetTPSUsers } from "../../services/UserManagement";


const Payee = props => {

    const [userModel, setUserModel] = useState(false);
    const [isNew, setIsNew] = useState(true)
    const [selectedRowData, setSelectedRowData] = useState(null)
    const [isDeleteModal, setDeleteModal] = useState(false);
    const deleteToggleModal = () => setDeleteModal(!isDeleteModal);

    const [isDeleteRowData, setDeleteRowData] = useState({});
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation()

    
    const [columns, setColumns] = useState([
        { title: t("NAME"), field: 'name',width: 200},
        { title: t("EMAIL"), field: 'email',width: 200},
        { title: t("MOBILE_NUMBER"), field: 'mobileNumber',width: 200},
        { title: t("STATUS"), field: 'isActive',width: 200,
            render: rowData => {
                const badgeColour = rowData.isActive === true ? "bg-success" : "bg-warning";
                const payeeType = rowData.isActive === true ? "Active" : "Deactivate";

                return <Badge className={`py-1 ${badgeColour}`}>{payeeType}</Badge>;
            },
        },
    ]);

    const [isTableData,setTableData] = useState([])

    useEffect(() => {
        fetchTPSUsers()
    }, [])

 
    const fetchTPSUsers = async () => {
        setLoading(true)
        try {
            const response = await GetTPSUsers()
            setTableData(response.result)
            setLoading(false)
        } catch (error) {
            toast.error("Error : fetch TPS Users")
            setLoading(false)
        }
    }


    const handleRowEdit = (rowData) => {
        setIsNew(false)
        setSelectedRowData(rowData)
        setUserModel(true)
    }


    const externalAdd = () => {
        setIsNew(true)
        setUserModel(true)
    }

    
  

    document.title = `${t("USER_MANAGEMENT")} | ${t("TPS_BACKOFFICE")}`;
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box p-0">
                        <Row>
                            <Col md={6}>
                                <Breadcrumbs title={t("TPS_BACKOFFICE")} breadcrumbItem={t("USER_MANAGEMENT")} mainTitleLink="/" titleLink="#" />
                            </Col>

                            {/*<Col md={6} className="d-flex justify-content-md-end py-3 py-md-4 gap-2">*/}
                            {/*    /!* <Link to="/payee-transaction-history"> *!/*/}
                            {/*        <Button color="primary w-md" >*/}
                            {/*            Add User*/}
                            {/*        </Button>*/}
                            {/*    /!* </Link> *!/*/}
                            {/*</Col>*/}
                        </Row>
                    </div>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">{t("USERS")}</CardTitle>

                                    {/*<MDBDataTable responsive striped bordered data={data} />*/}
                                    <div className="custom-table">
                                        <MaterialTableCustom
                                            data={isTableData}
                                            columns={columns}
                                            loading={loading}
                                            isSearch={false}
                                            handleRowEdit={handleRowEdit}
                                            // handleRowDelete={handleRowDelete}
                                            externalAdd={externalAdd}
                                        />
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* <>
                    <Modal isOpen={isDeleteModal} centered={true} toggle={deleteToggleModal}>
                        <ModalHeader toggle={null}>Confirmation</ModalHeader>
                        <ModalBody>
                            {t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PAYEE")}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={confirmDelete}>
                                {t("YES")}
                            </Button>{' '}
                            <Button color="secondary" onClick={deleteToggleModal}>
                                {t("NO")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </> */}

                    {userModel && (
                        <UserModel rowData={selectedRowData} fetchTPSUsers={fetchTPSUsers}  isOpen={true} isNew={isNew} toggleModal={() => {
                            setUserModel(false)
                            setSelectedRowData(null)
                        }}/>
                    )}

                </Container>
            </div>

        </React.Fragment>
    );
};

export default Payee;