import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, CardTitle, Label, Button, FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import { SaveThreshold, GetThreshold, UpdateThreshold } from "../../services/ThresholdService";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast } from "react-toastify";
import { Formik, Form, FieldArray } from "formik";
import FormTextField from "../../common/formComponents/FormTextField";
import * as Yup from "yup";

// Validation schema for Formik
const ThresholdSchema = Yup.object().shape({
  thresholds: Yup.array().of(
    Yup.object().shape({
      minimumThreshold: Yup.number()
        .required("Minimum threshold is required")
        .min(0, "Minimum threshold must be greater than or equal to 0"),
      maximumThreshold: Yup.number()
        .required("Maximum threshold is required")
        .min(
          Yup.ref("minimumThreshold"),
          "Maximum threshold must be greater than minimum threshold"
        ),
    })
  ),
});

const ThresholdManagement = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [thresholds, setThresholds] = useState([]);
  const user = useSelector((state) => state.auth.data?.backOfficeUserDTO);

  const fetchThreshold = useCallback(async () => {
    setLoading(true);
    try {
      const value = "THRESHOLD";
      const response = await GetThreshold(value);
      const config = JSON.parse(response.result.configuration);
      setThresholds(config.thresholds || []);
    } catch (error) {
      toast.error(t("Failed to get threshold!"));
    } finally {
      setLoading(false);
    }
  }, [t]);

  useEffect(() => {
    fetchThreshold();
  }, [fetchThreshold]);

  useEffect(() => {
    console.log("user", user);
  }, [user]);

  document.title = `${t("THRESHOLD_MANAGEMENT")} | ${t("TPS_BACKOFFICE")}`;

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const updatedThresholds = values.thresholds.map((threshold) => ({
        thresholdName: threshold.thresholdName,
        minimumThreshold: threshold.minimumThreshold,
        minimumThresholdCurrency: "USD",
        maximumThreshold: threshold.maximumThreshold,
        maximumThresholdCurrency: "USD",
      }));

      const data = {
        configType: "THRESHOLD",
        configName: "Threshold Management",
        configuration: JSON.stringify({ thresholds: updatedThresholds }),
      };

      await UpdateThreshold(data);

      toast.success(t("Threshold saved successfully!"));
      fetchThreshold(); // Refresh threshold after save
    } catch (error) {
      toast.error(t("Failed to save threshold!"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("TPS_BACKOFFICE")} breadcrumbItem={t("THRESHOLD_MANAGEMENT")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>{t("THRESHOLD_MANAGEMENT")}</CardTitle>
                <Formik
                  initialValues={{
                    thresholds: thresholds,
                  }}
                  validationSchema={ThresholdSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({ values, errors, touched }) => (
                    <Form>
                      <FieldArray name="thresholds">
                        {() => (
                          <>
                            {values.thresholds.map((threshold, index) => (
                              <div key={index}>
                                <Row>
                                  <Col md={4}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={`thresholds.${index}.thresholdName`}>
                                        {t("THRESHOLD_NAME")}
                                      </Label>
                                      <div>
                                        <strong>{t(threshold.thresholdName)}</strong>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={`thresholds.${index}.minimumThreshold`}>
                                        {t("MINIMUM_THRESHOLD")} (USD)
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name={`thresholds.${index}.minimumThreshold`}
                                        id={`thresholds.${index}.minimumThreshold`}
                                        type="number"
                                        placeholder={t("MINIMUM_THRESHOLD")}
                                        error={errors.thresholds?.[index]?.minimumThreshold}
                                        touched={touched.thresholds?.[index]?.minimumThreshold}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor={`thresholds.${index}.maximumThreshold`}>
                                        {t("MAXIMUM_THRESHOLD")} (USD)
                                        <sup className="text-danger"> *</sup>
                                      </Label>
                                      <FormTextField
                                        name={`thresholds.${index}.maximumThreshold`}
                                        id={`thresholds.${index}.maximumThreshold`}
                                        type="number"
                                        placeholder={t("MAXIMUM_THRESHOLD")}
                                        error={errors.thresholds?.[index]?.maximumThreshold}
                                        touched={touched.thresholds?.[index]?.maximumThreshold}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <hr />
                              </div>
                            ))}
                          </>
                        )}
                      </FieldArray>
                      <Row className="mt-4">
                        <Col>
                          <Button type="submit" color="primary" disabled={loading}>
                            {loading ? t("Saving...") : t("SAVE")}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ThresholdManagement;
