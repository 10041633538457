import { configureStore } from '@reduxjs/toolkit';

import authReducer from './reducers/authReducer';
import airewallexReducer from './reducers/airewallexReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    airwallex : airewallexReducer
  },
});

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
