import React, {useEffect} from 'react';
import {
    Badge,
    Button, Card, CardBody,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {useTranslation} from "react-i18next";

const PayeeTransactionEditModal = props => {
    const {isOpen,toggleModal,rowData} = props

    const { t } = useTranslation()

    useEffect(()=>{

    },[])


    console.log('xxx',rowData)

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggleModal} centered={true} size="lg">
                <ModalHeader style={{backgroundColor: 'rgb(3, 35, 112)', color: 'white' }}>{t("TRANSACTION_HISTORY_DETAILS")}</ModalHeader>
                <ModalBody>


                            <Row>

                            <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="dateAndTime">{t("PAYMENT_ID")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="dateAndTime"
                                            placeholder="2019-08-19,13:45"
                                            type="text"
                                            className="form-control"
                                            id="dateAndTime"
                                            value={rowData.payment_id}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>


                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="dateAndTime">{t("DATE_AND_TIME")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="dateAndTime"
                                            placeholder="2019-08-19,13:45"
                                            type="text"
                                            className="form-control"
                                            id="dateAndTime"
                                            value={rowData.date_and_time}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="paidAmount">{t("PAID_AMOUNT")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="paidAmount"
                                            placeholder={t("PAID_AMOUNT")}
                                            type="text"
                                            className="form-control"
                                            id="paidAmount"
                                            value={rowData.paid_amount}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="paidBy">{t("PAID_BY")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="paidBy"
                                            placeholder={t("PAID_BY")}
                                            type="text"
                                            className="form-control"
                                            id="paidBy"
                                            value={rowData.paid_by || '-'}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="transferRate">{t("TRANSFER_FEE")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="transferRate"
                                            placeholder={t("TRANSFER_FEE")}
                                            type="text"
                                            className="form-control"
                                            id="transferRate"
                                           // value={"1 SGD = 0.8 USD"}
                                            value={`${rowData.transfer_fee_amount || '0'} ${rowData.fee_currency || ''}`}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="payeeName">{t("PAYEE_NAME")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="payeeName"
                                            placeholder={t("PAYEE_NAME")}
                                            type="text"
                                            className="form-control"
                                            id="payeeName"
                                            value={rowData.payee_name || '-'}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="transferredAmount">{t("TRANSFERRED_AMOUNT")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="transferredAmount"
                                            placeholder={t("TRANSFERRED_AMOUNT")}
                                            type="text"
                                            className="form-control"
                                            id="transferredAmount"
                                            value={rowData.transfer_amount || '-'}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="accountNumber">{t("ACCOUNT_NUMBER")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="accountNumber"
                                            placeholder={t("ACCOUNT_NUMBER")}
                                            type="text"
                                            className="form-control"
                                            id="accountNumber"
                                            value={rowData.payee_account_number || '-'}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="transferredMode">{t("TRANSFERRED_MODE")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="transferredMode"
                                            placeholder={t("TRANSFERRED_MODE")}
                                            type="text"
                                            className="form-control"
                                            id="transferredMode"
                                            value={rowData.payment_method || '-'}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="transferredMode">{t("BANK_COUNTRY")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="bankCountry"
                                            placeholder={t("BANK_COUNTRY")}
                                            type="text"
                                            className="form-control"
                                            id="bankCountry"
                                            value={rowData.bank_country || '-'}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="purpose">{t("PURPOSE")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="purpose"
                                            placeholder={t("PURPOSE")}
                                            type="text"
                                            className="form-control"
                                            id="purpose"
                                            value={rowData.purpose.replace(/_/g, ' ')}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="bankCurrency">{t("BANK_CURRENCY")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="bankCurrency"
                                            placeholder={t("BANK_CURRENCY")}
                                            type="text"
                                            className="form-control"
                                            id="bankCurrency"
                                            value={rowData.paid_currency || '-'}
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={6} xl={6}>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="status">{t("STATUS")}</Label>
                                        <br/>
                                        {<div className="f-14">{rowData.payee_transaction_status}</div>}

                                    </FormGroup>
                                </Col>

                                {
                                    rowData.errorMessage ? (
                                        <Col md={6} xl={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="status">{t("ERROR_MESSAGE")}</Label>
                                                <br/>
                                                {<div className="f-14">{rowData.errorMessage}</div>}

                                            </FormGroup>
                                        </Col>
                                    ) : (
                                        <></>
                                    )
                                }

                            </Row>


                </ModalBody>
                <ModalFooter>
                    <Button color="secondary w-md" onClick={toggleModal}>{t("CLOSE")}</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default PayeeTransactionEditModal;