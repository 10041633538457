import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle, Badge, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {toast} from "react-toastify";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import {useSelector} from "react-redux";
import {GetAllKYBUsers, GetAllKYCUsers, GetKYBUserDetailsById} from "../../services/KYBDashboard";
import KycModel from "./components/KycModel";



const KYB_Submit = props => {

    const [editTooltip, setEditTooltip] = useState(false);
    const [isOpenPayeeTransactionHistoryModal, setIsOpenPayeeTransactionHistoryModal] = useState(false);
    const [clickedRowData, setClickedRowData] = useState(null);
    const toggleTransactionHistoryModal = () => setIsOpenPayeeTransactionHistoryModal(!isOpenPayeeTransactionHistoryModal);

    const [isDeleteModal, setDeleteModal] = useState(false);
    const deleteToggleModal = () => setDeleteModal(!isDeleteModal);
    const [selectedRowData, setSelectedRowData] = useState(null)

    const [isDeleteRowData, setDeleteRowData] = useState({});

    const [kycModel, setKycModel] = useState(false);

    const { t } = useTranslation()

    const [loading, setLoading] = useState(false);

   

    const [columns, setColumns] = useState([
        { title: t("FIRST_NAME"), field: 'firstName',width: 200},
        { title:  t("LAST_NAME"), field: 'lastName',width: 200},
        { title: t("EMAIL"), field: 'businessEmail',width: 200},
        { title: t("COUNTRY"), field: 'country',width: 200},
        { title: t("STATUS"), field: 'kycSubmitStatus',width: 200,
            render: rowData => {
                const badgeColour = rowData?.kycSubmitStatus?.toLowerCase() === "active" ? "bg-active" :
                    rowData?.kycSubmitStatus?.toLowerCase() === "pending" ? "bg-in-review" :
                    rowData?.kycSubmitStatus?.toLowerCase() === "submitted" ? "bg-new" :
                    rowData?.kycSubmitStatus?.toLowerCase() === "failed" ? "bg-danger" : "bg-ready-for-dispatch";
                return <Badge className={`py-1 ${badgeColour}`}>{rowData.kycSubmitStatus}</Badge>;
            },
        },
    ]);

    const [isTableData,setTableData] = useState([])

    const navigate = useNavigate();

    useEffect(()=>{
        fetchKYBDashboardUsers()
    },[])


    const fetchKYBDashboardUsers = async () =>{
        setLoading(true)
        try {
            const response = await GetAllKYCUsers()
            setTableData(response.result)
            setLoading(false)
        } catch (error) {
            toast.error("Error: Fetch KYB Users")
            setLoading(false)
        }
    }


    // table view button action event
    const handleRowEdit = (rowData) => {
        setClickedRowData(rowData)
        toggleTransactionHistoryModal();
    };

    const handleRowView = async (rowData) => {
        //setIsNew(false)
        setSelectedRowData(rowData)
        setKycModel(true)
    }




    document.title = `${t("KYB_DASHBOARD")} | ${t("TPS_BACKOFFICE")}`;
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box p-0">
                        <Row>
                            <Col md={6}>
                                <Breadcrumbs title={t("TPS_BACKOFFICE")} breadcrumbItem={t("KYB_DASHBOARD")} mainTitleLink="/" titleLink="#" />
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody className="custom-search-table-card">
                                    <CardTitle className="h4 custom-search-table-title">{t("KYB_USERS")}</CardTitle>

                                    {/*<MDBDataTable responsive striped bordered data={data} />*/}
                                    <div className="custom-table custom-search-table">
                                        <MaterialTableCustom
                                            data={isTableData}
                                            columns={columns}
                                            loading={loading}
                                            isSearch={true}
                                            // isDownloadDocuments={true}
                                            tableRowViewHandler={handleRowView}
                                        />
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {kycModel && (
                        <KycModel rowData={selectedRowData}  isOpen={true} toggleModal={() => {
                            setKycModel(false)
                            setSelectedRowData(null)
                        }}/>
                    )}

                </Container>
            </div>

        </React.Fragment>
    );
};

export default KYB_Submit;