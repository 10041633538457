import React, {useEffect, useState} from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container, FormGroup, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";
import FormTextField from "../../common/formComponents/FormTextField";
import FormDropdown from "../../common/formComponents/FormDropdown";
import ViewInquiryModel from "./components/ViewInquiryModel";
import InquiryEditModel from "./components/InquiryEditModel";
import {getAllInquiry, getInquiryStatus, InquirySearch} from "../../services/InquiryService";
import {toast} from "react-toastify";

const Inquiry = (props) => {

    const [inquiryEditModel, setInquiryEditModel] = useState(false);
    const [viewInquiryModel, setViewInquiryModel] = useState(false);
    const [clickedRowData, setClickedRowData] = useState([]);
    const [clickedEditRowData, setClickedEditRowData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isNew, setIsNew] = useState(true)
    const [loading, setLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [isInquiryStatus, setInquiryStatus] = useState([])
    const { t } = useTranslation()

    const user = useSelector(state => state.auth.data.backOfficeUserDTO)

    const hasReadPermission = (permissionType) => {
        const module = user?.permissions?.find(perm => perm.moduleCode === "INQUIRES");
        return module ? JSON.parse(module.permissions)[permissionType] : false;
    };

    const [columns, setColumns] = useState([
        { title: t("DATE_&_TIME"), field: 'date' ,width: 150},
        { title: t("INQUIRY_ID"), field: 'inquiryId',width: 150},
        { title: t("NAME"), field: 'name',width: 150},
        { title: t("MESSAGE_TITLE"), field: 'messageTitle',width: 200},
        { title: t("STATUS"), field: 'status',width: 150,
            render: rowData => {
                const badgeColor = rowData.status === "PENDING" ? "bg-warning" :
                    rowData.status === "COMPLETED" ? "bg-custom-success" :
                        rowData.status === "REJECTED" ? "bg-danger" : "bg-success";
                const badgeText = rowData.status;

                return <Badge className={`py-1 ${badgeColor}`}>{badgeText}</Badge>;
            },
        },
    ]);

    useEffect(()=>{
            fetchInquiryData()
            fetchPayeeRegisterData()
    },[])


    const fetchInquiryData = async () =>{

        try {

            const response = await getInquiryStatus()

            const data = response.result; // Assuming your response contains the data array

            // Transform data for setCountries
            const countriesData = data.map((country) => ({
                ...country,
                label: country.status.toUpperCase(),
                value: country.status.toUpperCase(), // Assuming you want the value in lowercase
            }));
            setInquiryStatus(countriesData)
        }catch (error){
            // toast.error(t("ERROR_FETCH_PAYEE_REGISTER_DATA"))
        }
    }

    const fetchPayeeRegisterData = async () =>{

        setLoading(true)

        try {

            const response = await getAllInquiry()


            let registerData = response.result.map((registerData) => {
                let formattedDate = "";

                if (registerData.createdAt) {
                    // Parse the date string and format it
                    const parsedDate = new Date(registerData.createdAt);

                    // Format the hours and minutes with leading zeros
                    const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
                    const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

                    formattedDate = `${parsedDate.getFullYear()}-${
                        parsedDate.getMonth() + 1
                    }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
                } else {
                    formattedDate = "";
                }

                return {
                    ...registerData,
                    date_and_time: formattedDate,
                };
            });

             const modifiedData = registerData.map(item => ({
                ...item,
                date: item.date_and_time,
                messageTitle:item.title,
                 name:item.firstName,
                 inquiryId:item.inquiryCode,
                 inquiryIdPk:item.inquiryId,
                status:item.status.toUpperCase(),
            }));

            setTableData(modifiedData)
            setLoading(false)
        }catch (error){
            setLoading(false)
            // toast.error(t("ERROR_FETCH_PAYEE_REGISTER_DATA"))
        }
    }


    const handleRowEdit = (rowData) => {
        setClickedEditRowData(rowData)
        setInquiryEditModel(true)
    }

    const tableRowViewHandler = (rowData) => {
        setClickedRowData(rowData);
        setViewInquiryModel(true)
    };

    const onSearchTransactionHistory = async (values) => {
        setLoadingSearch(true)

        let status = values.status === "Select an option" ? "" : values.status

        let data = {
            fromDate: values.periodFrom,
            toDate: values.periodTo,
            inquiryCode: values.inquiryId,
            status: status,
        }

        try {
            const response = await InquirySearch(data)

            const modifiedData = response.result.map(item => ({
                ...item,
                date: item.createdAt.split(' ')[0],
                messageTitle:item.title,
                name:item.firstName,
                inquiryId:item.inquiryCode,
                inquiryIdPk:item.inquiryId,
                status:item.status.toUpperCase(),
            }));
            setTableData(modifiedData)
            setLoadingSearch(false)
            // toggleModal()
        }catch (error){
            setLoadingSearch(false)
            if (error.response.data.result.error === "No inquiry found"){
                setTableData([])
                toast.error(t("NO_RECORD_FOUNDS"))
            }
            //toast.error(t("ERROR_INQUIRY_SEARCH"))
        }
    }

    const clearForm = (resetForm) => {
        resetForm()
        fetchPayeeRegisterData()
    };

    document.title = `${t("INQUIRES")} | ${t("TPS_BACKOFFICE")}`;


    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box p-0">
                        <Row>
                            <Col md={6}>
                                <Breadcrumbs title="TPS Solutions" breadcrumbItem={t("INQUIRES")} mainTitleLink="/" titleLink="#" />
                            </Col>

                        </Row>
                    </div>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            periodFrom: "",
                                            periodTo: "",
                                            status: "",
                                            inquiryId:"",
                                        }}
                                        //validationSchema={validationSchema}
                                        onSubmit={onSearchTransactionHistory}
                                    >
                                        {({ isSubmitting ,resetForm}) => (
                                            <Form>
                                                <Row>
                                                    <Col md={3} xl={3}>
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="periodFrom">
                                                                {t("PERIOD_FROM")}
                                                            </Label>
                                                            <FormTextField
                                                                name="periodFrom"
                                                                id="periodFrom"
                                                                type="date"
                                                                placeholder={t("PERIOD_FROM")}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={3} xl={3}>
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="periodTo">
                                                                {t("PERIOD_TO")}
                                                            </Label>
                                                            <FormTextField
                                                                name="periodTo"
                                                                id="periodTo"
                                                                type="date"
                                                                placeholder={t("PERIOD_TO")}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={6} xl={3}>
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="status">{t("STATUS")}</Label>
                                                            <FormDropdown
                                                                name="status"
                                                                id="status"
                                                                placeholder={t("STATUS")}
                                                                optionsValues={isInquiryStatus}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={6} xl={3}>
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="inquiryId">
                                                                {t("INQUIRY_ID")}
                                                            </Label>
                                                            <FormTextField
                                                                name="inquiryId"
                                                                id="inquiryId"
                                                                placeholder={t("INQUIRY_ID")}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    <Col
                                                        md={12}
                                                        xl={12}
                                                        className="d-flex justify-content-end"
                                                    >
                                                        <Button
                                                            className="btn-secondary w-md"
                                                            style={{marginRight:'10px'}}
                                                            type="button"
                                                            onClick={()=> clearForm(resetForm)}
                                                        >
                                                            {t("RESET")}
                                                        </Button>

                                                        <Button
                                                            className="btn-primary w-md"
                                                            type="submit"
                                                        >
                                                            {t("SEARCH")}{' '}{' '}
                                                            { loadingSearch ? (
                                                                <Spinner size="sm">
                                                                    Loading...
                                                                </Spinner>
                                                            ) : "" }
                                                        </Button>

                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4 mb-3">{t("INQUIRY_LIST")}</CardTitle>

                                    {/*<MDBDataTable responsive striped bordered data={data} />*/}
                                    <div className="custom-table">
                                        <MaterialTableCustom
                                            data={hasReadPermission("VIEW") ? tableData : []}
                                            columns={columns}
                                            loading={loading}
                                            isSearch={false}
                                            //tableRowViewHandler={tableRowViewHandler}
                                            handleRowEdit={hasReadPermission("CREATE") ? handleRowEdit : null}
                                        />
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                    {inquiryEditModel && (
                        <InquiryEditModel fetchPayeeRegisterData={fetchPayeeRegisterData} clickedEditRowData={clickedEditRowData} rowData={[]} fetchTPSUsers={[]}  isOpen={true} isNew={true} toggleModal={() => {
                            setInquiryEditModel(false)
                            //setSelectedRowData(null)
                        }}/>
                    )}

                    {viewInquiryModel && (
                        <ViewInquiryModel rowData={clickedRowData}  isOpen={true} isNew={true} toggleModal={() => {
                            setViewInquiryModel(false)
                            //setSelectedRowData(null)
                        }}/>
                    )}


                </Container>
            </div>

        </React.Fragment>
    )
}

export default Inquiry;
