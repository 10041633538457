import React, { useEffect, useState } from "react";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
// import { answersForSecurityQuestions } from "services/AuthService"
import { getSecurityQuestion } from "../../services/CommonService";
import FormTextField from "../../common/formComponents/FormTextField";
import { answersForSecurityQuestions, getSecurityQuestionById } from "../../services/AuthService";

const AnswerSecurityQuestions = ({
  setSecurityQuestionsVerified,
  coUserId,
}) => {
  const { t } = useTranslation();

  const [questions, setQuestions] = useState([
    { label: "In what city you were born?", value: "1" },
    { label: "What is the name of your favorite pet?", value: "2" },
    { label: "What high school do you attend?", value: "3" },
    { label: "What is the model of your first car?", value: "4" },
    { label: "What was your favorite food as a child?", value: "5" },
    { label: "Where did you meet your spouse?", value: "6" },
  ]);

  const [userQuestionDetails, setUserQuestionDetails] = useState([]);
  useEffect(() => {
    fetchSecurityQuestion();
  }, []);

  const fetchSecurityQuestion = async () => {
    try {
      const response = await getSecurityQuestionById(coUserId);
      setUserQuestionDetails(response.result); 
    } catch (error) {
      toast.error("Error: Security Question")
    }
  };

  const onSubmit = async (values) => {
    const data = userQuestionDetails.map((question, index) => {
      return {
        coUserId: coUserId,
        answerId: "null",
        questionId: question.securityQuestionId,
        answerText: values.answers[index],
      };
    });

    const answerData = {
      "answers":data
    }

    try {
      const response = await answersForSecurityQuestions(answerData)
      if(response.statusMessage == 'Success'){
        setSecurityQuestionsVerified(true);
      }

    } catch (error) {
      //toast.error("")
    }
  };

  const validationSchema = yup.object().shape({
    // answers: yup.array().of(
    //   yup.string().required('Answer is required') // You can add more validations here
    // ),
  });

  document.title = "Answer security questions!";
  return (
    <React.Fragment>
      <Row className="mt-3 mb-3">
        <Col md={12} style={{ marginTop: "20px" }}>
          <h4>Answer security questions!</h4>
        </Col>
      </Row>
      <Formik
        enableReinitialize
        initialValues={{
          answers: [],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <Row>
              {userQuestionDetails.map((userQuestion, index) => {
                return (
                  <>
                    <Col md={12}>
                      <FormGroup className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor={`answers[${index}]`}
                        >
                          {userQuestion.text}
                        </Label>
                        <FormTextField
                          name={`answers[${index}]`}
                          id={`answers[${index}]`}
                          placeholder={userQuestion.text}
                        />
                      </FormGroup>
                    </Col>
                  </>
                );
              })}

              <Col md={12} className="text-center mt-4">
                <button
                  className="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                >
                  Submit
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default AnswerSecurityQuestions;
