import React, {useState} from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Form,
    FormFeedback,
    CardTitle,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter, Container,
} from "reactstrap"
import {Link} from "react-router-dom";
import logoSm from "../../assets/images/logo/Logo-Icon.webp";

const ResetPassword = props => {
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showReNewPassword, setShowReNewPassword] = useState(false);

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleReNewPasswordVisibility = () => {
        setShowReNewPassword(!showReNewPassword);
    };

    document.title = "Reset Password"

    return (
        <React.Fragment>
            <div className="account-pages h-100vh">
                <Container className="h-100">
                    <Row className="justify-content-center align-items-center h-100">
                        <Col md={8} lg={7} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary">
                                    <div className="text-primary text-center p-4">
                                        <h5 className="text-white font-size-20 p-2">Reset Password</h5>
                                        <Link to="/index" className="logo logo-admin">
                                            <img src={logoSm} height="36" alt="logo" />
                                        </Link>
                                    </div>
                                </div>
                                <CardBody className="p-4">

                                    <Form className="mt-4 pt-3">
                                        <Row>
                                            <Col md={12} className="mb-3 text-center">
                                                <h6 className="font-size-16 mb-2">Enter New Password</h6>
                                            </Col>

                                            <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label" htmlFor="userNewpassword">New Password</Label>
                                                    <div className="input-group password-input-group">
                                                        <Input
                                                            name="password"
                                                            // value={validation.values.password || ""}
                                                            type={showNewPassword ? 'text' : 'password'}
                                                            className="form-control"
                                                            placeholder="Enter Password"
                                                            // onChange={validation.handleChange}
                                                            // onBlur={validation.handleBlur}
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary password-btn"
                                                                onClick={toggleNewPasswordVisibility}
                                                            >
                                                                {showNewPassword ? (
                                                                    <i className="fas fa-eye-slash"></i> // You may need to import FontAwesome or use another icon library
                                                                ) : (
                                                                    <i className="fas fa-eye"></i> // You may need to import FontAwesome or use another icon library
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>

                                            <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label" htmlFor="userNewpassword">Re-Enter New Password</Label>
                                                    <div className="input-group password-input-group">
                                                        <Input
                                                            name="password"
                                                            // value={validation.values.password || ""}
                                                            type={showReNewPassword ? 'text' : 'password'}
                                                            className="form-control"
                                                            placeholder="Enter Password"
                                                            // onChange={validation.handleChange}
                                                            // onBlur={validation.handleBlur}
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary password-btn"
                                                                onClick={toggleReNewPasswordVisibility}
                                                            >
                                                                {showReNewPassword ? (
                                                                    <i className="fas fa-eye-slash"></i> // You may need to import FontAwesome or use another icon library
                                                                ) : (
                                                                    <i className="fas fa-eye"></i> // You may need to import FontAwesome or use another icon library
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Col>

                                            <Col md={12} lg={12} className="text-center mt-3">
                                                <Button type="button" color="primary w-lg">
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>


                                    <Row className="mt-4">
                                        <Col md={12} className="text-center">
                                            <p className="mb-1">Remember It ? <Link to="/login" className="fw-medium text-primary"> Login Here </Link> </p>
                                            <p className="mb-0">
                                                © {new Date().getFullYear()} TPS Solutions. Crafted with{" "}
                                                <i className="mdi mdi-heart text-danger" /> by Zincat Technology
                                            </p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ResetPassword