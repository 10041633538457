import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SignIn } from '../../services/AuthService';
import i18n from '../../i18n';
import { axiosApi } from "../../helpers/api_helper"

// Async thunk for fetching 
export const customerAthenticateAsync = createAsyncThunk(
  'auth/customerAthenticate',
  async (data) => {
    const response = await SignIn(data);
    localStorage.setItem('airwallexOnBehalfToken', JSON.stringify(response.result?.backOfficeUserDTO['airwallexId']))
    localStorage.setItem('backOfficetoken', JSON.stringify(response.result.accessToken))
    // Set the new token in the Axios headers
    axiosApi.defaults.headers['Authorization'] = `${response.result.accessToken}`;

    localStorage.setItem('backOfficeRefreshToken', JSON.stringify(response.result.refreshToken))
    localStorage.setItem('backOfficeI18nextLng', response.result?.backOfficeUserDTO?.language)
    i18n.changeLanguage(response.result?.backOfficeUserDTO?.language)
    return response.result;
  }
);


const authSlice = createSlice({
  name: 'auth',
  initialState: { data: {}, loading : true, status: 'idle', error: null },
  reducers: {
    
     setAuthData: (state, action) => {
      state.data = action.payload;
      state.error = null
      localStorage.setItem('i18nextLng',action.payload.backOfficeUserDTO?.language)
      i18n.changeLanguage(action.payload.backOfficeUserDTO?.language)
    },

    clearAuthData: (state, action) => {
      state.data = {};
    },

    updateUserDetailsRedux : (state, action) => {
      state.data.userDTO = {...state.data?.backOfficeUserDTO, ...action.payload};
      state.error = null
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(customerAthenticateAsync.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
        state.data = {}
      })
      .addCase(customerAthenticateAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.loading = false
      })
      .addCase(customerAthenticateAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false
      })
    
  },
});

export const { setAuthData, clearAuthData, updateUserDetailsRedux } = authSlice.actions;
export default authSlice.reducer;
