import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row, Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import FormTextField from "../../../common/formComponents/FormTextField";
import FormPhoneNumberField from "../../../common/formComponents/FormPhoneNumberField";
import FormDropdown from "../../../common/formComponents/FormDropdown";
import { CreateTPSUser, UpdateTPSUser } from "../../../services/UserManagement";
import { toast } from "react-toastify";

const UserModel = (props) => {
  const { isOpen, toggleModal, rowData, isNew } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState([
    { value: t("ACTIVE"), label: "Active" },
    { value: t("DEACTIVATE"), label: "Deactivate" },
  ]);

  const onSubmit = async (values) => {
    setLoading(true)
    try {
      const data = {
        "backofficeUserId": rowData?.backofficeUserId ? rowData?.backofficeUserId : "",
        "email": values.email,
        "isActive": values.status === "Active" ? true : false,
        "mobileNumber": values.phone,
        "name": values.name
      }

      if(isNew){
        const response = await CreateTPSUser(data)
        props.fetchTPSUsers()
        setLoading(false)
        toast.success(t("REGISTER_USER_SUCCESS"))
        toggleModal()
      }else{
        const response = await UpdateTPSUser(data)
        props.fetchTPSUsers()
        setLoading(false)
        toast.success(t("UPDATE_USER_SUCCESS"))
        toggleModal()
      }
    } catch (error) {
      if (error.response.data.statusMessage === "Failure"){
        setLoading(false)
        toast(`Error: ${error.response.data.result.error[0]} `)
      }else {
        setLoading(false)
        toast(`Error: ${isNew ? t("USER_CREATE") : t("USER_UPDATE")} `)
      }
    }
  }

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("NAME_IS_REQUIRED")),
    email: yup.string().email(t("EMAIL_MUST_BE_VALID")).required(t("EMAIL_IS_REQUIRED")),
    phone: yup.string().required(t("PHONE_IS_REQUIRED")).min(8,t('PHONE_IS_REQUIRED')),
    status: yup.string().required(t("STATUS_IS_REQUIRED"))
        .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_STATUS"))
    ,
  });


  return (
    <div>
      <Modal isOpen={isOpen} centered={true} size="lg">
        <Formik
          enableReinitialize
          initialValues={{
            id: rowData?.id ? rowData?.id : "",
            name: rowData?.name ? rowData?.name : "",
            email: rowData?.email ? rowData?.email : "",
            phone: rowData?.mobileNumber ? rowData?.mobileNumber : "",
            status: rowData?.isActive === true ? "Active" : rowData?.isActive === false ? "Deactivate" : "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <ModalHeader>
                {isNew ? t("CREATE") : t("EDIT")} {t("USER")}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={6} xl={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="name">{t("NAME")}
                        <sup className="text-danger"> *</sup>
                      </Label>
                      <FormTextField
                        name="name"
                        id="name"
                        placeholder={t("NAME")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6} xl={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="email">{t("EMAIL")}
                        <sup className="text-danger"> *</sup>
                      </Label>
                      <FormTextField
                        name="email"
                        type="email"
                        id="email"
                        placeholder={t("EMAIL")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6} xl={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="phone">{t("MOBILE_NUMBER")}
                        <sup className="text-danger"> *</sup>
                      </Label>
                      <FormPhoneNumberField
                        name="phone"
                        id="phone"
                        // label={t("PHONE_NUMBER")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6} xl={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="status">{t("SELECT_STATUS")}
                        <sup className="text-danger"> *</sup>
                      </Label>
                      <FormDropdown
                        name="status"
                        id="status"
                        placeholder={t("SELECT_STATUS")}
                        optionsValues={status}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button className="secondary w-md" onClick={toggleModal}>
                  {t("CLOSE")}
                </Button>
                <Button className="btn-primary w-md" type="submit">
                   { isNew ? t("SAVE") : t("UPDATE")}{' '}{' '}
                  { loading ? (
                      <Spinner size="sm">
                        Loading...
                      </Spinner>
                  ) : "" }
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default UserModel;
