import { ErrorMessage, Field } from "formik";
import React from "react";
import { FormFeedback, Input } from "reactstrap";

const FormDropdown = ({
  name,
  id,
  placeholder,
  optionsValues,
  changeDropdown = null,
                        disabled=false
}) => {


  return (
    <Field name={name} as="select">
      {({ field, form }) =>
        changeDropdown ? (
          <div>
            <Input
              {...field}
              type="select"
              id={id}
              disabled={disabled}
              invalid={form.errors[name] && form.touched[name]}
              onChange={ (e) => {
                const selectedValue = e.target.value;
                changeDropdown(selectedValue,form);
              }}
            >
              <option value={null}>Select an option</option>
              {optionsValues.map((optionsValue, index) => (
                <option key={index} value={optionsValue.value}>
                  {optionsValue.label}
                </option>
              ))}
            </Input>
            <FormFeedback>
              <ErrorMessage name={name} />
            </FormFeedback>
          </div>
        ) : (
          <div>
            <Input
              {...field}
              type="select"
              id={id}
              disabled={disabled}
              invalid={form.errors[name] && form.touched[name]}
            >
              <option value={null}>Select an option</option>
              {optionsValues.map((optionsValue, index) => (
                <option key={index} value={optionsValue.value}>
                  {optionsValue.label}
                </option>
              ))}
            </Input>
            <FormFeedback>
              <ErrorMessage name={name} />
            </FormFeedback>
          </div>
        )
      }
    </Field>
  );
};

export default FormDropdown;
