import React from "react";
import {
  RouterProvider
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import scss
import "./assets/scss/theme.scss";

// Import CSS
import "./assets/css/custom-style.css";
import "./assets/css/custom-responsive.css";
import routes from "./routes/routes";

const App = () => {

  return (
    <React.Fragment>
      <RouterProvider router={routes} />
      <ToastContainer autoClose={2000} position="bottom-right" />
    </React.Fragment>
  );
};

export default App;
