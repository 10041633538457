import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import logoSm from "../../assets/images/logo/Logo-Icon.webp";
import { Formik, useFormik, Form } from "formik";
import * as yup from "yup";
import FormTextField from "../../common/formComponents/FormTextField";
import { useTranslation } from "react-i18next";
import { ChangePasswordService } from "../../services/AuthService";

const PasswordChange = ({coUserId}) => {
  const { t } = useTranslation()
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showReNewPassword, setShowReNewPassword] = useState(false);

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleReNewPasswordVisibility = () => {
    setShowReNewPassword(!showReNewPassword);
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required()
      .min(8, "Password Short")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
  });

  const onSubmit = async (values) =>  {
    try {
      const data = {
        "coUserId":coUserId,
        "newPassword": values.password
    }

    const response = await ChangePasswordService(data)
      //toast.success("Success")
    } catch (error) {
      //toast.error("Error")  
    }
  };

  document.title = "Answer security questions!";

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <Row>
              <Col md={12}>
                <FormGroup className="mb-3">
                  
                  {/* <Col md={6} lg={4}> */}
                    <FormGroup className="mb-3">
                      <Label className="form-label" htmlFor="userNewpassword">
                        {t("NEW_PASSWORD")}
                      </Label>
                      <div className="input-group password-input-group">
                        <FormTextField
                          name="password"
                          id="password"
                          placeholder={t("NEW_PASSWORD")}
                          type={showNewPassword ? "text" : "password"}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-secondary password-btn"
                            onClick={toggleNewPasswordVisibility}
                          >
                            {showNewPassword ? (
                              <i className="fas fa-eye"></i> // You may need to import FontAwesome or use another icon library
                            ) : (
                              <i className="fas fa-eye-slash"></i> // You may need to import FontAwesome or use another icon library
                            )}
                          </button>
                        </div>
                      </div>
                    </FormGroup>
                  {/* </Col> */}
                </FormGroup>
              </Col>

              <Col md={12} className="text-center mt-4">
                <button
                  className="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                >
                  Submit
                </button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default PasswordChange;
