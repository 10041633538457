import { get, post, put } from "../helpers/api_helper"
import * as url from "./url_helper"
import {UPDATE_TPS_USER} from "./url_helper";


export const GetTPSUsers = () => get(url.GET_TPS_USERS) 
export const CreateTPSUser = (data) => post(url.CREATE_TPS_USERS, data)
export const UpdateTPSUser = (data) => put(url.UPDATE_TPS_USER, data)


