import { ErrorMessage, Field } from "formik"
import React from "react"
import { FormFeedback, Input } from "reactstrap"

const FormTextField = ({
  name,
  id,
  placeholder,
  type = "text",
  disabled=false,
  changeInput = null,
    disablePastDate=false,
  style = {}
}) => {

  // const today = new Date().toISOString().split('T')[0];
  const { minHeight } = style;

  return (
    <Field name={name}>
      {({ field, form }) => (
        changeInput ? (
          <div className="flex-1">
          <Input
            {...field}
            type={type}
            placeholder={placeholder}
            id={id}
            style={{minHeight}}
            className="form-control"
            disabled={disabled}
            invalid={form.errors[name] && form.touched[name]}
            onChange={ (e) => {
             const selectedValue = e.target.value;
             changeInput(selectedValue, form);
            }}
            min={ disablePastDate ? new Date().toISOString().split('T')[0] : false} // Disable past dates
          />
          <FormFeedback>
            <ErrorMessage name={name} />
          </FormFeedback>
        </div>
        ) : (
          <div className="flex-1">
          <Input
            {...field}
            type={type}
            placeholder={placeholder}
            id={id}
            style={{minHeight}}
            className="form-control"
            disabled={disabled}
            invalid={form.errors[name] && form.touched[name]}
            min={ disablePastDate ? new Date().toISOString().split('T')[0] : false} // Disable past dates
          />
          <FormFeedback>
            <ErrorMessage name={name} />
          </FormFeedback>
        </div>
        )
        
      )}
    </Field>
  )
}

export default FormTextField
