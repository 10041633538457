import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import {  useSelector } from "react-redux";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useTranslation } from "react-i18next";

function DashBoard(props) {
  const { t } = useTranslation();


    document.title = t("DASHBOARD_TPS_SOLUTIONS");

  return (
    <React.Fragment>
      <div className="page-content">
        
          <Container fluid>
            <div className="page-title-box p-0">
              <Row>
                <Col md={6}>
                  <Breadcrumbs
                    title={t("TPS_BACKOFFICE")}
                    breadcrumbItem={t("DASHBOARD")}
                    mainTitleLink="/"
                    titleLink="#"
                  />
                </Col>

              </Row>

              
            </div>
          </Container>
      </div>
    </React.Fragment>
  );
}

export default DashBoard;
