
import React, { useState, useEffect } from "react"
import { Formik, Form } from "formik"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  FormFeedback,
  Input,
} from "reactstrap"


import {Link, useLocation, useNavigate} from "react-router-dom"

// import images
import logoSm from "../../assets/images/logo/Logo-Icon.webp"
import FormTextField from "../../common/formComponents/FormTextField"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { customerAthenticateAsync } from "../../store/reducers/authReducer"
import { useSelector } from "react-redux"

const Login = props => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch()


  const [showPassword, setShowPassword] = useState(false)

  const  loading = useSelector(state => state.auth.loading)
  const  data = useSelector(state => state.auth.data)
  const  status = useSelector(state => state.auth.status)
  const location = useLocation();

  // useEffect(() => {
  //   if(loading === false && Object.keys(data).length > 0){
  //     navigate("/dashboard")
  //   }
  // }, [ data, loading])

  const queryParams = new URLSearchParams(location.search);
  const redirectValue = queryParams.get("redirect");
  const redirectPath = redirectValue ? redirectValue : '/dashboard'

  useEffect(() => {

    if(status === "failed"){
      toast.error("Invalid Credentials")
      return
    }

    if(loading === false && status === "succeeded"  && Object.keys(data).length > 0){
      navigate(redirectPath)
    }

  }, [ data, loading])

  async function onSubmit(values) {
    const data = {
      username: values.email,
      password: values.password,
      type:"BackOffice"
    }
    try {
      dispatch(customerAthenticateAsync(data))
      // dispatch(get)
      // dispatch(loginUser(values, props.router.navigate))
      // const response = await SignUpRegister(values)
       //navigate("/dashboard")
    } catch (error) {
      toast.error("Authenticate Fail")
    }
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email(t("EMAIL_MUST_BE_VALID")).required(t("EMAIL_IS_REQUIRED")),
    password: yup.string().required(t("PASSWORD_IS_REQUIRED")),
  })

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }



  document.title = `${t("LOGIN")} | TPS Solutions`
  return (
    <React.Fragment>
      {/*<div className="home-btn d-none d-sm-block">*/}
      {/*  <Link to="/" className="text-dark">*/}
      {/*    <i className="fas fa-home h2" />*/}
      {/*  </Link>*/}
      {/*</div>*/}
      <div className="account-pages h-100vh bg-white overflow-auto">
        <Container className="h-100">
          <Row className="flex-wrap justify-content-center justify-content-lg-between align-items-center h-100 py-3">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mb-0">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      {t("WELCOME")} !
                    </h5>
                    <p className="text-white-70">
                      {t("SIGN_IN_TO_COUNTINUE_TO_TPS_BACKOFFICE")}
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="36" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="py-4 px-4 mt-4">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting, submitForm }) => (
                      <Form>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="username">
                            {t("EMAIL")}
                          </Label>
                          <FormTextField
                            name="email"
                            id="email"
                            placeholder={t("ENTER_EMAIL")}
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label" htmlFor="userpassword">
                            {t("PASSWORD")}
                          </Label>
                          <div
                            className="input-group password-input-group"
                            style={{
                              display: "grid",
                              gridTemplateColumns: "91% 9%",
                            }}
                          >
                            <FormTextField
                              name="password"
                              id="password"
                              placeholder={t("ENTER_PASSWORD")}
                              type={showPassword ? "text" : "password"}
                            />
                            <div className="input-group-append">
                              <button
                                type="button"
                                className="btn btn-secondary password-btn"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <i className="fas fa-eye"></i> // You may need to import FontAwesome or use another icon library
                                ) : (
                                  <i className="fas fa-eye-slash"></i> // You may need to import FontAwesome or use another icon library
                                )}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="mb-4 row">
                          {/*<div className="col-sm-6">*/}
                          {/*  <div className="form-check">*/}
                          {/*    <input*/}
                          {/*      type="checkbox"*/}
                          {/*      className="form-check-input"*/}
                          {/*      id="customControlInline"*/}
                          {/*    />*/}
                          {/*    <label*/}
                          {/*      className="form-check-label"*/}
                          {/*      htmlFor="customControlInline"*/}
                          {/*    >*/}
                          {/*      {t("REMEMBER_ME")}*/}
                          {/*    </label>*/}
                          {/*  </div>*/}
                          {/*</div>*/}

                          {/*<div className="col-sm-6 text-sm-end">*/}
                          {/*  <Link to="/forgot-password">*/}
                          {/*    <i className="mdi mdi-lock"></i>*/}
                          {/*    {t("FORGOT_YOUR_PASSWORD")}*/}
                          {/*  </Link>*/}
                          {/*</div>*/}
                        </div>

                        <div className="mb-4 row">
                          <div className="col-sm-12 text-center">
                            <button
                              className="btn btn-primary w-lg waves-effect waves-light"
                              type="submit"
                            >
                              {t("LOG_IN")}
                            </button>
                          </div>
                        </div>

                        <div className="text-center">
                          <p className="mb-0">
                            © {new Date().getFullYear()} TPS Solutions. 
                          </p>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>

            <Col md={8} lg={6} xl={7} className="d-none d-lg-block">
              <div className="d-flex justify-content-center align-items-end h-100 login-side br-1 bx-shadow-1">
                <div className="text-center p-4 mb-4">
                  <h5 className="text-primary-blue font-size-24 mb-3">
                    {t("WELCOME_TO_TPS_BACKOFFICE")}
                  </h5>
                  <p className="text-primary-blue">
                    A contemporary company account that combined corporate
                    cards, credit, money transfers, and computerized accounting
                    to help business save money
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


    </React.Fragment>
  )
}


export default Login

// Login.propTypes = {
//   error: PropTypes.any,
//   history: PropTypes.object,
//   loginUser: PropTypes.func,
// }
