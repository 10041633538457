
import React, { useEffect } from "react";



//redux
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { clearAuthData } from "../../store/reducers/authReducer";

const Logout = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    localStorage.removeItem('backOfficeRefreshToken')
    localStorage.removeItem('backOfficetoken')
    dispatch(clearAuthData())
    navigate('/login');
  }, [navigate]);
  


  return <></>;
};


export default Logout;
